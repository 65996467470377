/* eslint-disable new-cap */
import mixpanel from "mixpanel-browser"
import moment from "moment"
import TagManager from "react-gtm-module"

import { store } from "../redux/store"
import mixpanelService from "../services/mixpanel-service"
import vitallyService from "../services/vitally-service"
import { getConfigEnv } from "./config"

let userData = {}
let hasTeams = false

let sessionTime = new moment()

const appInfo = {}

function initializeEventListeners() {
  const config = getConfigEnv(window._env_)
  if (process.env.NODE_ENV === "production" && !config.REACT_APP_NO_ANALYTICS) {
    if (config.REACT_APP_GTM_ID) {
      TagManager.initialize({
        gtmId: config.REACT_APP_GTM_ID,
      })
    }

    mixpanel.init("aa5bc0474192ecaa5463dc982d1bac96")
  }
}

/**
 * Send event attribution
 *
 * @param {string} event - name of the event
 * @param {Object} data - payload
 */
async function sendEvent(event, data = {}) {
  if (!userData.id) {
    userData = store.getState().user.profile
  }

  mixpanelService.track(event, {
    distinct_id: userData.id,
    ...appInfo,
    ...data,
  })

  if (hasTeams) {
    const { teamId } = store.getState()?.teamManagement?.selectedTeam || {}
    await vitallyService.track(event, userData.id, teamId, userData.userTypeId, data)
  }
}

/**
 * Fires an event when a user fires NAVIGATE_TO or NAVIGATE_BACK actions
 *
 * @param {string} page
 */
function navigated(page) {
  sendEvent("Navigated to", { page })
}

/**
 * Fires an event when a user presses a Button
 *
 * @param {string} buttonText - copy on the button
 */
function buttonPressed(button) {
  sendEvent("Button pressed", { button })
}

/**
 * Set data to the user selected by distinct_id
 */
async function sessionStarted(additionalData = {}) {
  sessionTime = new moment()
  if (!userData.id) {
    userData = store.getState().user.profile
  }

  mixpanelService.identifyProfile(userData.id, {
    $name: userData.fullName,
    $email: userData.email,
    "Email verified": userData.emailConfirmed,
    $phone: userData.phone,
    userTypeId: userData.userTypeId,
    whitelabelId: userData.whitelabelId,
    $created: userData.createdAt,
    ...additionalData,
  })

  sendEvent("Session started")
}

/**
 * Set data to the user selected by distinct_id
 */
async function sessionStartedForVitally() {
  if (!hasTeams) {
    hasTeams = true

    await sendEvent("Session started")

    const config = getConfigEnv(window._env_)
    vitallyService.initNPS(config.REACT_APP_COMPANY_NAME || "our tool")
  }
}

/**
 * Fires an event when a user ends the session - logout|app state to background
 */
function sessionEnded() {
  userData = {}

  const x = new moment()
  const sessionDuration = moment.duration(x.diff(sessionTime)).as("milliseconds")
  const formatedDuration = moment.utc(sessionDuration).format("HH:mm:ss")

  sendEvent("Session ended", { "Session duration": formatedDuration })
}

/**
 * Fires an event when a users completes the registration process
 *
 * @param {object} user
 */
async function registrationCompleted(newUserData) {
  userData = newUserData

  if (window.dataLayer) {
    window.dataLayer.push({
      event: "product-register",
      eventProps: {
        category: "conversion",
        action: "button",
        label: "product-registered",
        value: 0,
      },
    })
  }

  const referral = localStorage.getItem("referral") || ""
  const additionalData = { referral_link_id: referral }
  await sessionStarted(additionalData)
  await sendEvent("Registration completed", additionalData)
}

/**
 * Fires an event when a users completes the registration process
 *
 * @param {object} user
 */
async function freeTrialStarted() {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "product-free-trial",
      eventProps: {
        category: "conversion",
        action: "button",
        label: "product-free-trial",
        value: 0,
      },
    })
  }

  await sendEvent("Free trial started")
}

/**
 * Fires an event when a users completes the registration process
 *
 * @param {object} user
 */
async function userLogin(newUserData) {
  userData = newUserData

  await sessionStarted()
  await sendEvent("User login")
}

/**
 * Fires an event when a user finishes a buy seats process
 *
 * @param {object} plans
 */
async function buySeats(plans, isFreeTrialEligible) {
  if (isFreeTrialEligible) {
    freeTrialStarted()
  }

  await sendEvent("Buy seats", { plans, isFreeTrialEligible })
}

export default {
  initializeEventListeners,
  buttonPressed,
  navigated,
  sessionStartedForVitally,
  sessionStarted,
  sessionEnded,
  registrationCompleted,
  userLogin,
  buySeats,
}
